const markets = {
  Markets: {
    reports: {
      title: 'گزارش مشکل',
      userAdded: 'کاربر',
      status: 'وضعیت',
      date: 'تاریخ ثبت',
      marketName: 'مربوط به آگهی',
      marketStatus: 'وضعیت آگهی',
      reason: 'دلیل گزارش',
      approved: 'تغییر وضعیت',
      show: 'مشاهده آگهی',
    },
  },
}

export default markets
