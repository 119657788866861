const setting = [
  {
    path: '/features',
    name: 'feature',

    component: () => import('@/pages/setting/features/Features.vue'),

    meta: {
      layout: 'content',
      resource: 'all',
    },
  },
  {
    path: '/options',
    name: 'options',

    component: () => import('@/pages/setting/options/OptionsPage.vue'),

    meta: {
      layout: 'content',
      resource: 'all',
    },
  },
  {
    path: '/setting/policy',
    name: 'policy-setting',

    component: () => import('@/pages/setting/policy.vue'),

    meta: {
      layout: 'content',

      action: 'can_edit_policy_setting',
      resource: 'all',
    },
  },
  {
    path: '/setting/seo',
    name: 'seo-setting',

    // component: () => import('@/views/setting/seo.vue'),

    meta: {
      layout: 'content',
      resource: 'all',
      action: 'can_see_seo_menu',
    },
  },
  {
    path: '/setting/FAQ',
    name: 'faq',

    component: () => import('@/pages/setting/FAQ.vue'),

    meta: {
      layout: 'content',
      resource: 'all',
      action: 'can_see_seo_faq',
    },
  },
]

export default setting
