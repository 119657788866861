import { mdiShieldAccount } from '@mdi/js'

export default [
  {
    title: 'تنظیمات دسترسی',
    icon: mdiShieldAccount,
    action: 'can_see_menu_access',
    resource: 'all',
    children: [
      {
        title: 'صفحات',
        to: 'access-pages',
        action: 'can_see_and_edit_pages',
        resource: 'all',
      },
      {
        title: 'نقش ها',
        to: 'access-roles',
        action: 'can_see_and_edit_roles',
        resource: 'all',
      },
      {
        title: 'مجوز ها',
        to: 'access-permissions',
        action: 'can_see_and_edit_permissions',
        resource: 'all',
      },
    ],
  },
]
