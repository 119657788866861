const collections = {
  Collections: {
    Application: {
      Title: 'برنامه ها',
      SelectedApp: 'لیست برنامه های انتخاب شده',
      Placeholder: 'عنوان کالکشن',
      EmptyList: 'از لیست بالا می توانید محصولات مورد نظر را به کاکشن اضافه کنید',
    },
    Create: 'ایجاد کالکشن',
    CreateBanner: 'ایجاد بنر',
    EditBanner: 'ویرایش بنر',
    CreateApp: 'ایجاد کالکشن برنامه',
    EditApp: 'ویرایش کالکشن برنامه',
    CreateSlide: 'ایجاد اسلایدر',
    EditSlider: 'ویرایش اسلایدر',
    App: 'برنامه',
    Banner: 'بنر',
    AddCollectionForm: 'فرم افزودن کالکشن',
    EditCollectionForm: 'فرم ویرایش کالکشن',
    SingleColumn: 'تک ستونه',
    TowColumns: 'دو ستونه',
    FourColumns: 'چهار ستونه',
    FirstColBig: 'یک ستون بزرگ و یک ستون کوچک',
    FirstColSmall: 'یک ستون کوچک و یک ستون بزرگ',
    ImgURL: 'آدرس URL عکس',
    Pattern: 'الگو',
    DeleteMessage: 'برای حذف این کالکشن مطمئن هستید؟',
    Limitation: {
      Label: 'محدودیت',
    },
    ChooseImg: 'انتخاب عکس',
    Uploading: 'در حال بارگذاری عکس',
    Banners: {
      title: 'لطفا طرح مورد نظر را انتخاب کنید.',
      changePattern: 'تغییر طرح',
      pic1: 'تصویر۱',
      pic2: 'تصویر۲',
      pic3: 'تصویر۳',
      pic4: 'تصویر۴',
    },
    SelectApp: 'انتخاب برنامه',
    RemoveApp: 'حذف برنامه',
    EnterUrl: 'اضافه کردن لینک',
    EnterTitle: 'اضافه کردن عنوان',
    EnterDesc: 'اضافه کردن توضیحات',
    Plan: 'طرح',
    Plan1: 'دارای جزئیات برنامه',
    Plan2: 'بدون جزئیات برنامه',
    Plan3: 'دارای جزئیات برنامه و اسلایدر',
    NotFound: 'هیچ آیتمی به این صفحه اضافه نشده است',
  },
}

export default collections
