<template>
  <v-fade-transition mode="out-in">
    <v-badge
      class="mt-1 fa-font animate"
      :content="count"
      :value="count"
      transition="scale-transition"
      color="primary"
      overlap
      left
      bottom
      offset-x="5"
    >
      <v-icon>
        {{ icons.mdiBellRingOutline }}
      </v-icon>
    </v-badge>
  </v-fade-transition>
</template>

<script>
import { computed } from '@vue/composition-api'
import store from '@/store'
import { mdiBellRingOutline } from '@mdi/js'

export default {
  setup() {
    const notifications = computed(() => store.getters['auth/getNotifications'])
    const count = computed(() => (notifications.value.tickets > 0 && notifications.value.auths > 0 ? 2 : 1))

    return {
      count,

      icons: {
        mdiBellRingOutline,
      },
    }
  },
}
</script>
