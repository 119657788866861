<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>
    <automatic-notification-component />
    <template #navbar="{ toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
      >
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
        </div>
        <div class="d-flex align-center right-row">
          <app-bar-bell />
          <app-bar-user-menu />
        </div>
      </div>
    </template>
    <template #footer>
      <snackbar-component />
      <div class="d-flex justify-space-between">
        <v-icon
          color="error"
          class="ms-2"
        >
          {{ icons.mdiHeartOutline }}
        </v-icon>
      </div>
    </template>
    <snackbar-component />
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@/@core/layouts/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical'
import SnackbarComponent from '@/components/common/SnackbarComponent.vue'
import AppBarUserMenu from '@/@core/layouts/components/app-bar/AppBarUserMenu.vue'
import AutomaticNotificationComponent from '@/components/common/AutomaticNotificationComponent.vue'
import AppBarBell from '@/@core/layouts/components/app-bar/AppBarBell.vue'
import { mdiMenu, mdiHeartOutline } from '@mdi/js'

export default {
  components: {
    AutomaticNotificationComponent,
    LayoutContentVerticalNav,
    AppBarUserMenu,
    AppBarBell,
    SnackbarComponent,
  },
  setup() {
    return {
      navMenuItems,

      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
