const markets = [
  {
    path: '/markets',
    name: 'markets',

    component: () => import('@/pages/markets/index.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_markets',
      resource: 'all',
    },
  },
  {
    path: '/change-request',
    name: 'change-request',
    component: () => import('@/pages/markets/changes-request/index.vue'),
    meta: {
      layout: 'content',
      action: 'can_see_change_request',
      resource: 'all',
    },
  },
  {
    path: '/market-report',
    name: 'market-report',
    component: () => import('@/pages/markets/reports/MarketReportPage.vue'),
    meta: {
      layout: 'content',
      action: 'can_see_market_reports',
      resource: 'all',
    },
  },
  {
    path: '/markets/show/:id',
    name: 'show-market',
    component: () => import('@/pages/markets/show.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_markets',
      resource: 'all',
    },
  },
  {
    path: '/change-request/show/:id',
    name: 'show-change-request',
    component: () => import('@/pages/markets/changes-request/show.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_show_change_request',
      resource: 'all',
    },
  },
]

export default markets
