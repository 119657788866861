import { mdiMessageTextOutline } from '@mdi/js'

export default [
  {
    title: 'تیکت ها',
    action: 'can_see_tickets',
    icon: mdiMessageTextOutline,
    resource: 'all',
    children: [
      {
        title: 'لیست تیکت ها',
        to: 'tickets',
        action: 'can_see_markets',
        resource: 'all',
      },
      {
        title: 'الگوها',
        to: 'tickets-templates',
        action: 'can_see_tickets_template',
        resource: 'all',
      },
    ],
  },
]
