const cat = [
  {
    path: '/categories',
    name: 'categories',

    component: () => import('@/pages/categories/index.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_categories',
      resource: 'all',
    },
  },
  {
    path: '/categories/create',
    name: 'create-category',

    component: () => import('@/pages/categories/create.vue'),

    meta: {
      layout: 'content',
      action: 'can_create_categories',
      resource: 'all',
    },
  },
  {
    path: '/categories/edit/:id',
    name: 'edit-category',

    component: () => import('@/pages/categories/edit.vue'),

    meta: {
      layout: 'content',
      action: 'can_edit_categories',
      resource: 'all',
    },
  },
]

export default cat
