const business = [
  {
    path: '/business',
    name: 'business',

    // component: () => import('@/views/business/MarketReportPage.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_business',
      resource: 'all',
    },
  },
  {
    path: '/business/create',
    name: 'create-business',

    // component: () => import('@/views/business/add_edit_digital.vue'),

    meta: {
      layout: 'content',
      action: 'can_create_business',
      resource: 'all',
    },
  },
  {
    path: '/business/edit/:id',
    name: 'edit-business',

    // component: () => import('@/views/business/add_edit_digital.vue'),

    meta: {
      layout: 'content',
      action: 'can_edit_business',
      resource: 'all',
    },
  },
]

export default business
