import { mdiCog } from '@mdi/js'

export default [
  {
    title: 'تنظیمات',
    icon: mdiCog,
    action: 'can_see_setting',
    resource: 'all',
    children: [
      {
        title: 'ویژگی های بازارچه',
        to: 'feature',
        action: 'can_see_setting_features',
        resource: 'all',
      },
      {
        title: 'آپشن های بازارچه',
        to: 'options',
        action: 'can_see_setting_options',
        resource: 'all',
      },
      {
        title: 'تغییر متن قوانین و ارتباط با ما',
        to: 'policy-setting',
        action: 'can_edit_policy_setting',
        resource: 'all',
      },
      {
        title: 'تنظیمات سئو',
        to: 'seo-setting',
        resource: 'all',
        action: 'can_see_seo_menu',
      },
      {
        title: 'سوالات متداول',
        to: 'faq',
        resource: 'all',
        action: 'can_see_seo_menu',
      },
    ],
  },
]
