import appConfigStoreModule from '@/store/module-app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './module-app'
import auth from './module-auth'
import routes from './module-routes'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appConfig: appConfigStoreModule,
    app,
    auth,
    routes,
  },
})
