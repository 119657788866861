const places = {
  Places: {
    TitleInput: 'عنوان مکان',
  },
  Cities: {
    Title: 'شهرها',
    AddNew: 'افزودن شهر جدید ',
    TitleInput: 'عنوان شهر',
    EnTitle: 'عنوان انگلیسی',
    CreateTime: 'تاریخ ایجاد',
    Edit: 'ویرایش',
    Parent: 'استان',

    Rules: {
      TitleInput: 'عنوان شهر را وارد کنید',
      EnTitle: 'عنوان انگلیسی را وارد کنید',
      RemoveConfirm: '"آیا از حذف مطمعن هستید؟"',
    },
  },
  Countries: {
    Title: 'کشور ها',
    AddNew: 'افزودن کشور جدید ',
    TitleInput: 'عنوان کشور',
    EnTitle: 'عنوان انگلیسی',
    CreateTime: 'تاریخ ایجاد',
    Edit: 'ویرایش',

    Rules: {
      TitleInput: 'عنوان کشور را وارد کنید',
      EnTitle: 'عنوان انگلیسی را وارد کنید',
      RemoveConfirm: '"آیا از حذف مطمعن هستید؟"',
    },
  },
  Provinces: {
    Title: 'استان ها',
    AddNew: 'افزودن استان جدید ',
    TitleInput: 'عنوان استان',
    EnTitle: 'عنوان انگلیسی',
    CreateTime: 'تاریخ ایجاد',
    Edit: 'ویرایش',
    Parent: 'کشور',

    Rules: {
      TitleInput: 'عنوان استان را وارد کنید',
      EnTitle: 'عنوان انگلیسی را وارد کنید',
      RemoveConfirm: '"آیا از حذف مطمعن هستید؟"',
    },
  },
  Towns: {
    Title: 'شهرستان ها',
    AddNew: 'افزودن شهرستان جدید ',
    TitleInput: 'عنوان شهرستان',
    EnTitle: 'عنوان انگلیسی',
    CreateTime: 'تاریخ ایجاد',
    Edit: 'ویرایش',
    Parent: 'شهر',

    Rules: {
      TitleInput: 'عنوان شهرستان را وارد کنید',
      EnTitle: 'عنوان انگلیسی را وارد کنید',
      RemoveConfirm: '"آیا از حذف شهرستان مطمعن هستید؟"',
    },
  },
}

export default places
