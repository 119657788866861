const common = {
  Common: {
    Search: 'جستجو',
    Details: 'جزيیات',
    OsType: 'سیستم عامل',
    Type: 'نوع',
    Save: 'ذخیره',
    UnAuthenticated: 'احراز نشده',
    Authenticated: 'احراز شده',
    NotFoundData: 'موردی جهت نمایش وجود ندارد',
    CantChangePass: 'امکان تغییر کلمه عبور برای دیگران وجود ندارد',
    Close: 'بستن',
    Inventory: 'موجودی',
    InCrease: 'افزایش',
    DeCrease: 'کاهش',
    Toman: 'تومان',
    AmountOf: 'مقدار',
    Reason: 'دلیل',
    NoData: 'موردی جهت نمایش وجود ندارد.',
    active: 'فعال',
    no_active: 'غیر فعال',
    Amount‌: 'مبلغ',
    SelectReason: 'انتخاب دلیل',
    NewPassword: 'رمز جدید',
    Decreased: 'کاهش یافته',
    Increased: 'افزایش یافته',
    Password: 'رمز عبور',
    Wallet: 'کیف پول',
    Transactions: 'تراکنش ها',
    Tickets: 'تیکت ها',
    ConfirmPassword: 'تایید رمز',
    Edit: 'ویرایش',
    Remove: 'حذف',
    Cancel: 'لغو',
    SuccessFull: 'موفق',
    Bill: 'فاکتور',
    UnSuccessFull: ' نا موفق',
    Show: 'نمایش',
    InCreaseValidity: 'افزایش اعتبار',
    DeCreaseValidity: 'کاهش اعتبار',
    Today: 'امروز',
    Security: 'امنیت',
    PageSize: 'سایز صفحه',
    Yesterday: 'دیروز',
    LastWeek: 'هفته گذشته',
    LastMount: 'ماه گذشته',
    Last3Mount: '۳ ماه گذشته',
    Last6Mount: '۶ ماه گذشته',
    No_Show: 'عدم نمایش',
    Mandatory: 'اجباری',
    SimpleHistory: 'تاریخ',
    Optional: 'اختیاری',
    Row: 'ردیف',
    Telegram: 'تلگرام',
    Instagram: 'اینستاگرام',
    WebSite: 'آدرس وب سایت',
    PostalCode: 'کد پستی',
    AddQuestion: 'افزودن سوال ',
    DoActive: 'فعال کردن',
    DoBlock: 'بلاک کردن',
    Status: {
      Label: 'وضعیت',
      Active: 'فعال',
      DeActive: 'غیرفعال',
      Block: 'بلاک شده',
      Default: 'پیش فرض',
      Normal: 'عادی',
    },
    Role: {
      Label: 'نقش',
      Developer: 'برنامه نویس',
      Admin: 'مدیر',
      TopAdmin: 'مدیر با دسترسی بالا',
      TopUser: 'کاربر با دسترسی بالا',
      User: 'کاربر',
    },
    Sort: {
      Label: 'مرتب سازی',
      Desc: 'نزولی',
      Asc: 'صعودی',
    },
    Error: {
      Required: 'وارد کردن فیلد الزامی است.',
      EmailValidator: 'لطفا یک ایمیل معتبر وارد کنید.',
      PasswordValidator: 'کلمه عبور باید شامل حروف کوچک و بزرگ، کاراکتر خاص و حداقل طول 8 کاراکتر باشد',
      IntegerValidator: 'فیلد باید عددی باشد.',
      RegexValidator: ' فیلد نامعتبر است',
      DontPersonInput: 'لطفا کیبورد خود را به انگلیسی تغییر دهید.',
      DiscountPercentValidator: 'درصد وارد شده اشتباه است',
      DiscountAmountValidator: 'قیمت وارد شده اشتباه است',
      ConfirmPasswordValidator: 'رمز ها مطابقت ندارند',
      InvalidProperty: 'مقدار وارد شده نامعتبر می باشد',
      SimplePasswordValidator: 'کلمه عبور باید شامل  حداقل طول 8 کاراکتر باشد',
    },
    New: 'جدید',
    WaitingForAnswer: 'در انتظار پاسخ',
    Answerd: 'پاسخ داده شده',
    Answer: 'پاسخ دادن  ',
    EnterYourResponse: 'پاسخ خود را وارد کنید',
    Closed: 'بسته شده',
    SeeAttachedFile: 'مشاهده فایل پیوست',
    Title: 'عنوان',
    IsCommon: 'متداول',
    CommonQuestion: 'سوالات متداول',
    LearnUseText: 'متن آموزش استفاده ',
    AddPattern: 'افزودن الگو',
    Pattern: ' الگو',
    Hour: 'ساعت',
    Confirm: 'تایید',
    Reject: 'رد',
    Back: 'بازگشت',
    Filter: {
      Phone: 'جستجو بر اساس شماره تلفن',
      Email: 'جستجو بر اساس ایمیل',
      UserRule: 'جستجو بر اساس سطح کاربر',
      UserStatus: 'جستجو بر اساس وضعیت کاربر',
    },
    Alert: {
      Success: 'تغییرات با موفقیت انجام شد',
      Delete: 'با موفقیت حذف شد',
      Insert: 'ذخیره با موفقیت انجام شد',
    },
    Application: 'برنامه',
    Ios: 'اپل',
    Android: 'اندروید',
    Game: 'بازی',
    Loading: 'در حال دریافت اطلاعات ... لطفا منتظر باشید.',
    AccountType: {
      Real: 'حقیقی',
      Legal: 'حقوقی',
    },
    Person: 'نفر',
    Count: 'تعداد',
    Date: 'تاریخ',
    Order: {
      label: 'جهت نمایش',
      vertical: 'عمودی',
      horizontal: 'افقی',
    },
    Yes: 'بلی',
    No: 'خیر',
    History: 'تاریخچه',
    Days: {
      Saturday: 'شنبه',
      Sunday: 'یکشنبه',
      Monday: 'دوشنبه',
      Tuesday: 'سه‌شنبه',
      Wednesday: 'چهارشنبه',
      Thursday: 'پنج‌شنبه',
      Friday: 'جمعه',
    },
    Expire: 'انقضا',
    Errors: {
      Unknown: 'خطایی رخ داده',
    },
  },
}

export default common
