const access = [
  {
    path: '/access/roles',
    name: 'access-roles',

    // component: () => import('@/views/access/Roles.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_and_edit_roles',
      resource: 'all',
    },
  },
  {
    path: '/access/permissions',
    name: 'access-permissions',

    // component: () => import('@/views/access/Permissions.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_and_edit_permissions',
      resource: 'all',
    },
  },
  {
    path: '/access/pages',
    name: 'access-pages',

    // component: () => import('@/views/access/Pages.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_and_edit_pages',
      resource: 'all',
    },
  },
]

export default access
