const baseRoutes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/pages/dashboard/Home.vue'),
    meta: {
      layout: 'content',
      action: 'can_see_dashboard',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/pages/Error404.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/pages/auth/NotAuthorized.vue'),
    meta: {
      resource: 'Public',
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

export default baseRoutes
