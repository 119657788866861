const menus = {
  Menu: {
    Dashboard: 'داشبورد',
    cities: 'شهر ها',
    Categories: 'دسته‌بندی‌ها',
    Application: 'برنامه‌ها',
    ApplicationRequest: 'درخواست ها',
    Users: 'کاربران',
    UsersList: 'لیست کاربران',
    Setting: 'تنظیمات',
    Tickets: 'تیکت ها',
    TicketList: 'لیست تیکت ها',
    TicketPatterns: ' الگو ها',
    TicketGroups: 'گروه‌ها',
    Comments: 'نظرات',
    CommentApprove: 'تایید نظرات',
    Devices: 'دستگاه‌ها',
    Notification: 'اطلاعیه‌ها',
    Access: 'تنظیمات دسترسی',
    Financial: 'مالی',
    BankAccount: 'مدیریت کارت بانکی کاربران',
    Transactions: 'تراکنش ها',
    Collections: 'کالکشن ها',
    ManagePageWeb: 'مدیریت صفحات وب',
    ManagePageMobile: 'مدیریت صفحات موبایل',
    ManagePageApps: 'صفحه برنامه ها',
    ManagePageGames: 'صفحه بازی ها',
    SearchPage: '  صفحه جستجو',
    DetailPage: 'صفحه جزییات محصول',
    DeveloperNotConfirmed: 'احراز توسعه‌دهندگان',
  },
}

export default menus
