const devices = {
  Device: {
    Name: 'نام دستگاه',
    Label: 'برچسب دستگاه',
    Serial: 'سریال دستگاه',
    Udid: 'udid دستگاه',
    Imei: 'imei دستگاه',
    IsDefault: 'پیش فرض بودن دستگاه',
  },
}

export default devices
