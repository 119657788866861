const notifications = {
  Notifications: {
    Add: 'ایجاد اطلاعیه',
    Form: {
      Create: {
        Header: 'فرم افزودن اطلاعیه',
      },
      Edit: {
        Header: 'فرم ویرایش اطلاعیه',
      },
      Title: {
        Title: 'عنوان',
        Placeholder: 'عنوان را وارد کنید.',
      },
      Content: {
        Title: 'محتوا',
        Placeholder: 'محتوا را وارد کنید.',
      },
      Date: {
        Title: 'تاریخ انتشار',
        Placeholder: 'تاریخ انتشار را وارد کنید.',
      },
      Files: {
        Title: 'فایل پیوست',
        Placeholder: 'فایل پیوست را وارد کنید.',
      },
    },
    Show: {
      Creator: 'ایجاد شده توسط',
      CreatedAt: 'تاریخ ایجاد',
      Files: 'فایل های پیوست',
      Status: 'وضعیت',
      Seen: 'نمایش داده شده',
      Unseen: 'نمایش داده نشده',
    },
  },
}

export default notifications
