// eslint-disable-next-line
import { mdiCityVariant, mdiFileTreeOutline, mdiHomeOutline } from '@mdi/js'
// eslint-disable-next-line
import access from './access'
import markets from './markets'
import logs from './logs'
import setting from './setting'
import ticket from './ticket'
import users from './users'
import places from './places'

export default [
  {
    title: 'داشبورد',
    icon: mdiHomeOutline,
    to: 'home',
    action: 'can_see_dashboard',
    resource: 'all',
  },
  {
    title: 'دسته بندی ها',
    icon: mdiFileTreeOutline,
    to: 'categories',
    action: 'can_see_categories',
  },
  ...places,
  ...markets,
  ...ticket,
  ...setting,
  ...access,
  ...users,
  ...logs,
]
