import { canNavigate } from '@/plugins/acl/routeProtection'
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import access from './routing/access'
import authRoutes from './routing/authRoutes'
import baseRoutes from './routing/baseRoutes'
import business from './routing/business'
import categories from './routing/categories'
import changes from './routing/changes'
import places from './routing/places'
import logs from './routing/logs'
import markets from './routing/markets'
import setting from './routing/setting'
import tickets from './routing/tickets'
import users from './routing/users'

Vue.use(VueRouter)

const routes = [
  ...baseRoutes,
  ...authRoutes,
  ...access,
  ...categories,
  ...users,
  ...business,
  ...changes,
  ...tickets,
  ...setting,
  ...logs,
  ...markets,
  ...places,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

const auth = {
  loggedIn() {
    return store.getters['auth/getToken'] !== '' || localStorage.getItem('token') !== null
  },
}

router.beforeEach((to, _, next) => {
  if (to.meta.requiresAuth) {
    if (!canNavigate(to)) {
      // Redirect to login if not logged in

      if (!auth.loggedIn()) {
        return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })
      }

      // If logged in => not authorized
      return next({ name: 'not-authorized' })
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && auth.loggedIn()) {
    next('/')
  }

  return next()
})

export default router
