const cat = [
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('@/pages/tickets/index.vue'),
    meta: {
      layout: 'content',
      action: 'can_see_tickets',
      resource: 'all',
    },
  },
  {
    path: '/ticket-templates',
    name: 'tickets-templates',
    component: () => import('@/pages/tickets/templates.vue'),
    meta: {
      layout: 'content',
      action: 'can_see_tickets_template',
      resource: 'all',
    },
  },
  {
    path: '/ticket/:id',
    name: 'show_ticket',
    component: () => import('@/pages/tickets/ticketView.vue'),
    meta: {
      layout: 'content',
      action: 'can_see_tickets',
      resource: 'all',
    },
  },
]

export default cat
