const logs = [
  {
    path: '/logs/api',
    name: 'log-api',

    // component: () => import('@/views/logs/Api.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_log_api',
      resource: 'all',
    },
  },
]

export default logs
