const comments = {
  Comment: {
    Show: {
      CreatedAt: 'تاریخ ایجاد',
      Content: 'متن پیام',
    },
    Search: {
      Comment: {
        Title: 'شناسه نظر',
        Placeholder: 'شناسه نظر را وارد کنید.',
      },
      FromDate: {
        Title: 'از تاریخ',
      },
      ToDate: {
        Title: 'تا تاریخ',
      },
      Status: {
        0: 'تایید نشده',
        1: 'تایید شده',
      },
    },
    Message: {
      Reject: 'آیا از رد کردن این آیتم اطمینان دارید؟',
      Confirm: 'آیا از تایید کردن این آیتم اطمینان دارید؟',
    },
    SeeMore: 'مشاهده جزییات',
    AppName: 'نام اپلیکیشن',
    Rate: 'رتبه بندی',
    Details: 'جزيیات',
    Developer: 'توسعه دهنده',
    Confirm: 'تایید نظر',
    Reject: 'رد نظر',
  },
}

export default comments
