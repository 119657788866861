const changes = [
  {
    path: '/changes',
    name: 'changes',

    // component: () => import('@/views/changes/editChanges.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_ad_changes',
      resource: 'all',
    },
  },
  {
    path: '/changes/market/show/:id',
    name: 'show-market-changes',

    // component: () => import('@/views/changes/showChanges.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_ad_changes',
      resource: 'all',
    },
  },
  {
    path: '/changes/business/show/:id',
    name: 'show-business-changes',

    // component: () => import('@/views/changes/showChangeBusiness.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_ad_changes',
      resource: 'all',
    },
  },
]

export default changes
