<template>
  <v-snackbar
    v-model="$store.state.auth.showNotification"
    top
    centered
    transition="scroll-y-transition"
    color="info"
    :timeout="-1"
    class="fa-font snack-custom"
    width="100%"
  >
    <div style="height: 15px;">
      <v-btn
        class="close-notifications"
        icon
        size="20"
        small
        @click="$store.state.auth.showNotification = false"
      >
        <v-icon
          color="white"
          size="20"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <div
      v-for="item of messages"
      :key="item.message"
      class="pb-2 pr-5 message"
    >
      <v-avatar
        color="secondary"
        pacity="0.75"
        size="24"
      >
        <span
          class="white--text"
          style="font-size: 12px"
        >
          {{ item.count }}
        </span>
      </v-avatar>
      {{ item.message }}
    </div>
  </v-snackbar>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { computed } from '@vue/composition-api'
import store from '@/store'

export default {
  setup() {
    const notifications = computed(() => store.getters['auth/getNotifications'])

    const messages = computed(() => {
      const text = []
      if (notifications.value.tickets > 0) {
        text.push({ message: 'تیکت پاسخ داده نشده وجود دارد .', count: notifications.value.tickets })
      }
      if (notifications.value.auths > 0) {
        text.push({ message: ' احراز تایید نشده وجود دارد .', count: notifications.value.auths })
      }

      return text
    })

    return {
      messages,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
.close-notifications {
  position: absolute;
  top: 6px;
  right: -1px;
  padding: 10px 24px;
}

.message {
  font-size: 13px;
}

.snack-custom {
  padding-top: 20px !important;

  .v-sheet.v-snack__wrapper {
    border-radius: 14px !important;
  }

  .v-application.theme--light .v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
    box-shadow: 0 2px 10px 0 rgb(94 86 105 / 50%) !important;
  }
}

@media (max-width: 900px) {
  .snack-custom {
    padding-top: 0px !important;
  }
}
</style>
