const users = {
  User: {
    Label: 'کاربر',
    AllUsers: 'مجموع کاربران',
    UserList: 'لیست کاربران',
    Id: 'شماره کاربری ',
    Profile: 'پروفایل کاربر',
    Name: 'نام و نام خانوادگی',
    MobileNumber: 'شماره همراه',
    Email: 'ایمیل',
    UserId: 'شماره کاربری',
    UserPhone: 'شماره همراه ',
    OverallInfo: 'اطلاعات کلی',
    Role: 'سطح کاربری',
    Info: 'اطلاعات کاربری',
    RegisterHistory: 'تاریخ ثبت نام',
    ConfirmMobileHistory: 'تاریخ تایید شماره موبایل ',
    LastCheckHistory: 'آخرین تاریخ ورود به سایت',
    Serial: 'سریال',
    Udid: 'udid',
    Imei: 'imei',
    IsDefault: 'پیشفرض بودن',
    DeleteDevice: 'حذف دستگاه',
    SeeUser: 'مشاهده کاربر',
    AccountType: 'نوع اکانت',
    NationalId: 'شناسه‌ملی',
    Tel: 'تلفن',
    Address: 'آدرس',
    PostalCode: 'کدپستی',
    EconomicCode: 'کداقتصادی',
    NationalCardImage: 'کارت‌ملی',
    CompanyImage: 'ثبت',
    PendingStatus: 'در انتظار تایید',
    RejectedStatus: 'رد شده',
    ConfirmedStatus: 'تایید شده',
    Status: 'وضعیت',
    AuthenticationUsers: 'احراز هویت توسعه دهندگان',
    RejectReson: 'دلیل رد',
    UpgradeToAdmin: 'ارتقا به ادمین',
    UpgradeTitle: 'ارتقا کاربر {name} به ادمین',
    IncreaseReasons: {
      IncreaseForWeb: 'افزایش اعتبار به جهت هدیه وب سایت',
      IncreaseForUnSuccessTransaction: 'افزایش اعتبار به جهت برگشت اعتبار در تراکنش ناموفق',
      IncreaseForEmptyProduct: 'افزایش اعتبار به جهت برگشت اعتبار در تراکنش ناموفق',
      IncreaseForCanceling: 'افزایش اعتبار به جهت کنسل شدن خرید توسط کاربر',
      IncreaseForCancelingFromAdmin: 'افزایش اعتبار به جهت کنسل شدن خرید توسط کاربر',
    },
    DecreaseReasons: {
      DecreaseForMistake: 'کاهش اعتبار به جهت اشتباه محاسباتی',
      DecreaseForPenalty: 'کاهش اعتبار به جهت جریمه',
      DecreaseForCheck: 'کاهش اعتبار به جهت بررسی تراکنش ها',
    },
    TransactionsFields: {
      Number: 'شماره تراکنش',
      Type: 'نوع تراکنش',
      Status: 'وضعیت تراکنش',
      Amount: 'مبلغ تراکنش',
      History: 'تاریخ تراکنش',
    },
    Change: {
      ToUser: ' تغییر سطح به کاربر',
      ToAdmin: 'تغییر سطح به مدیر',
    },
    ChangePass: 'لطفا رمز عبور برای کاربر مورد نظر تعیین کنید',
  },
}

export default users
