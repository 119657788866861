const users = [
  {
    path: '/users',
    name: 'users',

    component: () => import('@/pages/users/index.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_user_list',
      resource: 'all',
    },
  },
  {
    path: '/users/:id',
    name: 'users-show',

    component: () => import('@/pages/users/show.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_user_detail',
      resource: 'all',
    },
  },
  {
    path: '/user_black_list',
    name: 'user-black-list',

    component: () => import('@/pages/users/BlackList.vue'),

    meta: {
      layout: 'content',
      action: 'can_see_and_edit_block_list',
      resource: 'all',
    },
  },
]

export default users
