const auth = {
  Auth: {
    Login: {
      Title: 'به پنل مدیریت خوش آمدید',
      SubTitle: 'لطفا وارد حساب کاربری خود شوید.',
      Button: 'ورود به پنل',
      SendCodeButton: 'ارسال کد ورود',
      Code: 'کد ورود',
      CheckCodeButton: 'تایید کد',
      Mobile: 'شماره‌همراه',
      Password: 'کلمه عبور',
      Email: 'ایمیل',
      LoginWithPassword: 'ورود با کلمه‌ عبور',
      LoginWithOTP: 'ورود با کد یکبار مصرف',
      TryAnotherWay: 'می خواهید روش دیگری را امتحان کنید؟',
      PasswordRepeat: 'تکرار کلمه عبور',
      ResetPassword: 'بازیابی رمز عبور',
      ForgetPassword: 'رمز عبور خود را فراموش کردید؟',
      Username: 'نام کاربری',
      NewCodeAndPassword: 'لطفا کد ارسال شده و رمز عبور جدید را وارد کنید.',
      EnterPhone: 'لطفا شماره موبایل خود را وارد کنید.',

    },
  },
}

export default auth
