export default {
  Dashboard: {
    Chart: {
      User: {
        Title: 'آمار کاربران',
        Footer: ' آمار تعداد کاربران ثبت نامی',
        Today: 'امروز',
        Yesterday: 'دیروز',
        OneWeekAgo: 'یک هفته گذشته',
        OneMonthAgo: 'یک ماه گذشته',
        ThreeMonthAgo: 'سه ماه گذشته',
        SixMonthAgo: 'شش ماه گذشته',
      },
      Markets: {
        Title: 'آمار آگهی ها',
        Footer: ' آمار تعداد آگهی های ثبت شده',
        Today: 'امروز',
      },
      Payment: {
        Title: 'تراکنش های مالی',
        Footer: 'آمار تراکنش های موفق و ناموفق',
      },
    },
    Developer: {
      Title: 'لیست توسعه‌دهندگان در انتظار تایید',
      NotFound: 'توسعه‌دهندگان در انتظار تاییدی وجود ندارد.',
      Table: {
        User: 'توسعه‌دهنده',
      },
    },
    Ticket: {
      Title: 'لیست تیکت‌های در انتظار پاسخ',
      NotFound: 'تیکت در انتظار پاسخی وجود ندارد.',
      Table: {
        Number: 'شماره تیکت',
        User: 'کاربر',
      },
    },
    Comment: {
      Title: 'لیست نظرات در انتظار تایید',
      NotFound: 'نظر در انتظار تاییدی وجود ندارد.',
      Table: {
        Application: 'برنامه',
        User: 'کاربر',
      },
    },
    Markets: {
      Title: 'آگهی های در انتظار بررسی',
      Count: 'آمار تعداد برنامه های دانلود شده',
      Table: {
        Title: 'لیست آگهی های در انتظار بررسی',
        NotFound: 'آگهی در انتظار برسی وجود ندارد',
        Ad: 'عنوان آگهی',
        User: 'کاربر',
      },
      Reports: {
        Title: 'لیست گزارش مشکل های در انتظار بررسی',
        NotFound: 'گزارش مشکلی در انتظار بررسی وجود ندارد',
        Reason: 'مشکل اعلامی',
        Date: 'تاریخ ثبت',
      },
      RequestReports: {
        Title: 'لیست درخواست های ویرایش آگهی ',
        NotFound: 'درخواست ویرایش آگهی وجود ندارد',
        Ad: 'عنوان آگهی',
        Date: 'تاریخ ثبت',
      },
    },
    Card: {
      Title: 'لیست کارت های در انتظار تایید',
      Num: 'شماره کارت',
    },
  },
}
