const categories = {
  Categories: {
    CreatedAt: 'تاریخ ایجاد :',
    Status: 'وضعیت',
    RemoveConfirm: 'آیا از حذف دسته بندی مطمئن هستید؟',
    Icon: 'آیکن',
    ExampleText: 'مثال دسته بندی',
    Description: 'توضیحات',
    ShortDesc: 'توضیحات کوتاه',
    MetaDescription: 'توضیحات متا',
    ChooseOpts: ' انتخاب آپشن ',
    ShowInWork: 'نمایش در کسب و کار ',
    ShowInMarket: 'نمایش در بازارچه ',
    ShowInJamadadi: 'نمایش در کوپن ',
    ShowInRecruitment: 'نمایش در استخدام ',

    Filter: {
      Search: 'جستجوی دسته بندی',
      SearchByType: 'جستجو براساس نوع دسته بندی',
      Children: {
        Default: 'بدون انتخاب',
        Work: 'کسب و کار',
        Market: 'بازارجه',
        Recruitment: 'استخدام',
      },
    },
    Form: {
      Create: {
        Header: 'فرم افزودن دسته بندی',
      },
      Edit: {
        Header: 'فرم ویرایش دسته بندی',
      },
      Image: {
        Title: 'آپلود تصویر',
        Placeholder: 'یک تصویر را انتخاب کنید.',
      },
      Name: {
        Title: 'نام',
        Placeholder: 'نام را وارد کنید',
      },
      En_name: {
        Title: 'نام لاتین',
        Placeholder: 'نام لاتین را وارد کنید.',
      },
      Slug: {
        Title: 'اسلاگ',
        Placeholder: 'اسلاگ را وارد کنید.',
      },
      Seo_title: {
        Title: 'عنوان سئو',
        Placeholder: 'عنوان سئو را وارد کنید.',
      },
      Sort: {
        Title: 'ترتیب',
      },
      Active: {
        Title: 'وضعیت',
      },
      Desc: {
        Title: 'توضیحات کوتاه',
      },
      Notif: {
        Title: 'نوتیفیکیشن',
      },
    },
    Add: 'ایجاد دسته‌بندی',
    Children: {
      Add: 'افزودن زیردسته',
      Title: 'فرم افزودن زیر دسته',
    },
    Errors: {
      Image: 'تصویری برای دسته بندی انتخاب نشده است.',
    },
  },
}

export default categories
