const applications = {
  Applications: {
    Applications: 'برنامه‌ها',
    Icon: 'ایکون',
    FaName: 'عنوان',
    OsType: 'سیستم‌عامل',
    Type: 'نوع',
    Developer: 'سازنده',
    ConfirmVersion: 'ورژن تایید شده',
    OtherOs: 'نسخه‌دیگر',
    Build: 'بیلد',
    Img_Icons: 'تصاویر و نماد‌ها',
    Images: 'تصاویر',
    Info: 'اطلاعات',
    Bundle: 'باندل',
    Slug: 'اسلاگ',
    Apple: 'اپل',
    Android: 'اندروید',
    Has: 'دارد',
    Hasnt: 'ندارد',
    Direct_Download: 'دانلود مستقیم',
    Status: {
      Label: 'وضعیت نسخه',
      Draft: 'پیش‌نویس',
      WaitingForCheck: 'در انتظار بررسی',
      Approved: 'تایید شده',
      Reject: 'رد شده',
    },
    BuyType: {
      Label: 'پرداخت',
      Free: 'رایگان',
      InApp: 'پرداخت درون برنامه‌ای',
      Monetary: 'پولی',
    },
    Version: {
      Label: 'ورژن',
      Draft: 'پیش‌نویس',
      WaitingForCheck: 'در انتظار بررسی',
      Checking: 'در حال بررسی',
      Approved: 'تایید شده',
      Inactive: 'غیر فعال',
    },
    alert: {
      add_success: 'اپلیکیشن شما با موفقیت ایجادشد',
      edit_success: 'تغییرات شما با موفقیت اعمال شد',
    },
    information_form: {
      title: 'اطلاعات برنامه',
      sub_title: 'برای سهولت در پر کردن فرم زیر میتوانید از اطلاعات  برنامه های موجود خود استفاده کنید',
      form: {
        price: 'قیمت',
        type: 'نوع برنامه',
        key_words: 'کلمات کلیدی',
        key_words_hint: 'برای افزودن  enter را فشار دهید',
        main_category: 'دسته های اصلی',
        second_category: 'دسته های فرعی',
        display_name: 'نام نمایشی',
        english_name: 'نام نمایشی انگلیسی',
        subtitle: 'زیرنوشت',
        details: 'توضیحات',
        english_details: 'توضیحات انگلیسی',
        in_app_payments: 'آیا برنامه شما پرداخت درون برنامه ای دارد؟',
        application: 'اپلیکشن',
        play: 'بازی',
        has_ads: 'آگهی درون برنامه ای دارد؟',
        ipad_mode: 'آیا برنامه شما با ipad سازگار است؟',
        age_group: 'گروه سنی',
        tags: 'تگ‌ها',
        categories: 'دسته‌بندی‌ها',
      },
      in_app_payments: {
        title: 'پرداختهای درون برنامه ای',
        btn: 'افزودن برنامه جدید',
      },
      age_groups: {
        more_than_four: '+4',
        more_than_nine: '+9',
        more_than_twelve: '+12',
        more_than_seventeen: '+17',
      },
    },
    support: {
      title: 'اطلاعات پشتیبانی',
      email: 'ایمیل پشتیبانی ',
      phone: 'شماره تماس پشتیبانی',
      address: 'ادرس وبسایت برنامه',
    },
    upload_images: 'آپلود عکس‌ها  و تصاویر',
    upload_version: 'آپلود نسخه',
    upload_screenshot: 'آپلود اسکرین شات‌ها',
    check_box: 'استفاده از _Type_ _OsType_',
    app_icon: 'یک تصویر به عنوان آیکن انتخاب کنید',
    file_property: {
      type: 'نوع فایل',
      aspect_ratio: 'نسبت ابعاد',
      minimum_length: 'حداقل طول',
      maximum_volume: 'حداکثر حجم',
    },
    comments: {
      with_out_answer: 'بدون پاسخ',
      with_answer: 'پاسخ داده شده',
    },
    approve: {
      title: 'بررسی نسخه ها',
    },
    Builds: {
      File: {
        Download: 'دانلود فایل',
      },
      Logs: {
        Title: 'لیست تغییرات بیلد',
        Table: {
          Reason: 'دلیل',
          Type: 'نوع',
        },
      },
    },
    Size: {
      Label: 'حجم',
      Megabyte: 'مگابایت',
    },
    Versions: {
      Title: 'نسخه‌ها',
      Form: {
        Edit: 'ویرایش نسخه',
        View: 'نمایش نسخه',
        FaName: {
          Title: 'نام‌نمایشی',
          Placeholder: 'نام‌نمایشی را وارد کنید.',
        },
        EnName: {
          Title: 'نام‌نمایشی  انگلیسی',
          Placeholder: 'نام‌نمایشی را وارد کنید.',
        },
        Summary: {
          Title: 'زیرنوشت          ',
          Placeholder: 'زیرنوشت را وارد کنید.',
        },
        Type: {
          Title: 'نوع نسخه',
        },
        Description: {
          Title: 'توضیحات',
          Placeholder: 'توضیحات را وارد کنید.',
        },
        NewFeature: {
          Title: 'ویژگی های جدید',
          Placeholder: 'ویژگی های جدید را وارد کنید.',
        },
        Category: {
          Title: 'دسته‌‌بندی‌اصلی',
          Placeholder: 'دسته‌‌بندی‌اصلی را وارد کنید.',
        },
        SubCategory: {
          Title: 'دسته‌‌بندی‌فرعی',
          Placeholder: 'دسته‌‌بندی‌فرعی را وارد کنید.',
        },
        Price: {
          Title: 'قیمت',
          Placeholder: 'قیمت را وارد کنید.عدد صفر به معنای رایگان است.',
        },
        Ads: {
          Title: 'آگهی درون برنامه ای دارد؟',
        },
        Ipad: {
          Title: 'آیا برنامه شما با ipad سازگار است؟',
        },
        BuyType: {
          Title: 'آیا برنامه شما پرداخت درون برنامه ای دارد؟',
        },
        Support: {
          Email: {
            Title: 'ایمیل پشتیبانی',
            Placeholder: 'ایمیل پشتیبانی را وارد کنید.',
          },
          Phone: {
            Title: 'شماره تماس پشتیبانی',
            Placeholder: 'شماره تماس پشتیبانی را وارد کنید.',
          },
          WebSite: {
            Title: 'ادرس وبسایت برنامه',
            Placeholder: 'ادرس وبسایت برنامه را وارد کنید.',
          },
        },
      },
      Type: {
        All: 'همه',
        ThirtyTwo: '32 بیت',
        SixtyFour: '64 بیت',
      },
      Status: {
        Draft: 'پیش‌نویس',
        WaitingForCheck: 'در انتظار بررسی',
        DoingCheck: 'در حال بررسی',
        Approved: 'تایید شده',
        Reject: 'رد شده',
        Cancel: 'کنسل شده',
      },
      History: {
        Title: 'تاریخچه وضعیت نسخه',
        Operator: 'اپراتور',
      },
    },
    SimilarApps: 'برنامه‌های‌مشابه',
  },
}
export default applications
