const places = [
  {
    path: '/countries',
    name: 'countries',
    component: () => import('@/pages/places/CountryPage.vue'),
    meta: {
      layout: 'content',
      action: 'can_see_place_country',
      resource: 'all',
    },
  },
  {
    path: '/provinces',
    name: 'provinces',
    component: () => import('@/pages/places/ProvincePage.vue'),
    meta: {
      layout: 'content',
      action: 'can_see_place_province',
      resource: 'all',
    },
  },
  {
    path: '/places',
    name: 'cities',
    component: () => import('@/pages/places/CityPage.vue'),
    meta: {
      layout: 'content',
      action: 'can_see_place_city',
      resource: 'all',
    },
  },
  {
    path: '/towns',
    name: 'towns',
    component: () => import('@/pages/places/TownPage.vue'),
    meta: {
      layout: 'content',
      action: 'can_see_place_town',
      resource: 'all',
    },
  },
]

export default places
