<template>
  <div v-if="notification">
    <v-snackbar
      v-model="notification.isShow"
      :timeout="notification.timeout || 3000"
      :bottom="isBottom"
      :right="isRight"
      top
      centered
      :color="notification.color"
      :outlined="isOutlined"
    >
      {{ notification.message }}
    </v-snackbar>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import store from '@/store'

export default defineComponent({
  name: 'SnackBarComponent',
  props: {
    isRight: Boolean,
    isBottom: Boolean,
    isOutlined: Boolean,
  },
  setup() {
    const notification = computed(() => store.state.appConfig.notification)

    return {
      notification,
    }
  },
})
</script>

//you can use this code to use the component: store.commit('appConfig/SHOW_NOTIFICATION', {isShow: true,color: 'info', message: 'test',timeout: 3000})
