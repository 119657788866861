import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import 'element-tiptap/lib/index.css'
import { ElementTiptapPlugin } from 'element-tiptap'
import Vue from 'vue'
import VueMomentJalaali from 'vue-moment-jalaali'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(ElementTiptapPlugin, {
  dir: 'rtl',
})

// automaticNotification()
const moment = require('moment-jalaali')

moment.loadPersian({ usePersianDigits: true })

Vue.use(VueMomentJalaali)
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
