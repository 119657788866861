import { mdiAccountBoxMultiple } from '@mdi/js'

export default [
  {
    title: 'کاربران',
    icon: mdiAccountBoxMultiple,
    action: 'can_see_menu_user',
    resource: 'all',
    children: [
      {
        title: 'لیست کاربران',
        to: 'users',
        action: 'can_see_user_list',
        resource: 'all',
      },
      {
        title: 'بلاک لیست',
        to: 'user-black-list',
        action: 'can_see_and_edit_block_list',
        resource: 'all',
      },
    ],
  },
]
