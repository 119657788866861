const setting = {
  Setting: {
    Property: 'ویژگی ها',
    Name: 'نام',
    Unit: 'واحد',
    HaveUnit: 'دارای واحد',
    No_HaveUnit: 'بدون واحد',
    PropertyName: 'نام ویژگی',
    ConfirmRemoveMsg: 'آیا از حذف این سطر مطمئن هستید؟',
    QuestionInput: 'لطفا متن سوال را وارد کنید',
    AnswerInput: 'لطفا پاسخ سوال را وارد کنید',
    DeleteConfirm: 'آیا از حذف این سوال مطمئن هستید؟',
    Categories: 'دسته بندی ها',
    Contracts: {
      Title: 'مدیریت قراردادها',
      General: ' متن قوانین عمومی',
      Sell: ' متن قوانین فروش',
    },
    Package: {
      Title: 'مدیریت بسته ها',
      Action: {
        Store: 'افزودن بسته جدید',
        Update: 'ویرایش بسته',
        HaveDiscount: 'تخفیف دار',
        NoDiscount: 'بدون تخفیف',
        Discount: 'تخفیف',
        DiscountType: 'نوع تخفیف',
        DiscountAmount: 'مبلغ تومان',
        DiscountPercent: 'درصد',
        Toman: 'تومان',
      },
      Table: {
        Name: 'عنوان',
        Type: 'نوع',
        Duration: 'مدت زمان (روز)',
        Price: 'قیمت',
        RevokePrice: 'هزینه ثبت مجدد',
        Description: 'توضیحات',
        Restriction: 'محدودیت',
        isActive: 'وضعیت',
        Risk: 'ریسک',
        DiscountAmount: 'میزان تخفیف',
      },
      Type: {
        Subscription: 'اشتراک',
        Consumable: 'قابل مصرف',
        NonConsumable: 'غیرقابل مصرف',
      },
      Restriction: {
        FullAccess: 'دسترسی کامل',
        SingleApplication: 'تک اپلیکیشن',
        MultiApplication: 'چند اپلیکیشن',
        RegisterUdidOnly: 'ثبت نامی',
      },
      Revoke: {
        Low: 'پایین',
        Medium: 'متوسط',
        High: 'بالا',
      },
    },
    Cache: {
      Add: {
        Btn: 'افزودن',
        Title: 'افزودن کش',
      },
      Edit: {
        Title: 'ویرایش کش',
      },
      Title: 'کش',
      Key: 'کلید',
      Name: 'نام',
      Expiration: 'انقضاء',
      LastCreated: 'آخرین ایجاد',
      NextCreated: 'بروز رسانی بعدی',
      Clear: {
        Message: 'آیا از پاک کردن این کش مطمئن هستید؟',
      },
      ExpirationType: {
        Title: 'نوع انقضاء',
        Type: {
          Seconds: 'ثانیه',
          Minutes: 'دقیقه',
          Hours: 'ساعت',
          Days: 'روز',
        },
      },
    },
    Gate: {
      Title: 'درگاه ها',
      Status: 'وضعیت',
      Default: 'پیش فرض',
      Percent: 'درصد کارمزد',
      Max: 'بیشترین کارمزد',
      Min: 'کمترین کارمزد',
      Discount: 'کارمزد',
      Yes: 'دارد',
      No: 'ندارد',
      GateTitle: 'عنوان درگاه',
    },
  },
}

export default setting
