export default {
  Access: {
    Pages: {
      Menu: 'صفحات',
      Add: 'ایجاد صفحه',
      Edit: 'ویرایش صفحه',
      Form: {
        Name: {
          Title: 'نام',
          Placeholder: 'نام را وارد کنید.',
        },
      },
      Show: {
        CreatedAt: 'تاریخ ایجاد',
      },
    },
    Permissions: {
      Menu: 'مجوز‌ها',
      Add: 'ایجاد مجوز',
      Edit: 'ویرایش مجوز',
      Form: {
        Name: {
          Title: 'مجوز',
          Placeholder: 'مجوز را وارد کنید.',
        },
        Title: {
          Title: 'عنوان',
          Placeholder: 'عنوان را وارد کنید.',
        },
        Page: {
          Title: 'صفحه را انتخاب کنید.',
        },
      },
      Show: {
        CreatedAt: 'تاریخ ایجاد',
        Page: 'مربوط به صفحه',
      },
    },
    Roles: {
      Menu: 'نقش‌ها',
      Add: 'ایجاد نقش',
      Edit: 'ویرایش نقش',
      Form: {
        Name: {
          Title: 'نام',
          Placeholder: 'نام را وارد کنید.',
        },
        Message: 'برای حذف این نقش مطمئن هستید؟',
      },
      Show: {
        Title: 'عنوان',
        PermissionAdd: 'افزودن مجوز',
        UserAdd: 'افزودن کاربر',
        Edit: 'ویرایش',
        Delete: 'حذف',
        Modal: {
          Permissions: {
            Title: 'افزودن مجوز',
          },
          Users: {
            Title: 'افزودن کاربر',
          },
        },
        Users: 'لیست کاربران',
      },
    },
  },
}
