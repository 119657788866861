import { mdiStorePlus } from '@mdi/js'

export default [
  {
    title: 'مکان ها',
    icon: mdiStorePlus,
    action: 'can_see_place_menu',
    resource: 'all',
    children: [
      {
        title: 'کشور ها',
        to: 'countries',
        action: 'can_see_place_country',
        resource: 'all',
      },
      {
        title: 'استان ها',
        to: 'provinces',
        action: 'can_see_place_province',
        resource: 'all',
      },
      {
        title: 'شهر ها',
        to: 'cities',
        action: 'can_see_place_city',
        resource: 'all',
      },
      {
        title: 'شهرستان ها',
        to: 'towns',
        action: 'can_see_place_town',
        resource: 'all',
      },
    ],
  },
]
