export default {
  Tickets: {
    AdTitle: 'آگهی',
    Close: 'بستن تیکت',
    CloseMessage: 'آیا از بستن تیکت اطمینان دارید؟',
    Groups: {
      Edit: 'ویرایش گروه',
      Form: {
        Name: {
          Title: 'نام',
          Placeholder: 'نام را وارد کنید.',
        },
      },
      Show: {
        Title: 'عنوان',
        UserAdd: 'افزودن کاربر',
        Edit: 'ویرایش',
        Modal: {
          Users: {
            Title: 'افزودن کاربر',
          },
        },
        Users: 'لیست کاربران',
      },
    },
    Referral: {
      Title: 'ارجاع',
      Message: 'ارجاع شده از گروه _GROUP_',
      Modal: {
        Add: {
          Title: 'ارجاع به گروه دیگر',
          Confirm: 'ارجاع',
          Groups: 'انتخاب گروه',
        },
        Delete: {
          Message: 'ارجاع به گروه _GROUP_ حذف شود؟',
          Title: 'حذف',
        },
      },
    },
    Comments: {
      Title: 'نظرات',
      Header: 'لیست نظٰرات',
    },
    Answer: {
      SelectPattern: 'افزودن متن از الگو',
    },
  },
}
