export default {
  Login: {
    Title: 'به پنل مدیریت خوش آمدید',
    SubTitle: 'لطفا وارد حساب کاربری خود شوید.',
    Button: 'ورود به پنل',
    SendCodeButton: 'ارسال کد ورود',
    Code: 'کد ورود',
    CheckCodeButton: 'تایید کد',
    Mobile: 'شماره‌همراه',
    Password: 'کلمه عبور',
    PasswordRepeat: 'تکرار کلمه عبور',
    Email: 'ایمیل',
    LoginWithPassword: 'ورود با کلمه‌ عبور',
    LoginWithOTP: 'ورود با کد یکبار مصرف',
    TryAnotherWay: 'می خواهید روش دیگری را امتحان کنید؟',
    ResetPassword: 'بازیابی رمز عبور',
    ForgetPassword: 'رمز عبور خود را فراموش کردید؟',
    Username: 'نام کاربری',
    NewCodeAndPassword: 'لطفا کد ارسال شده و رمز عبور جدید را وارد کنید.',
    EnterPhone: 'لطفا شماره موبایل خود را وارد کنید.',
  },
  Setting: {
    Contracts: {
      Title: 'مدیریت قراردادها',
      General: ' متن قوانین عمومی',
      Sell: ' متن قوانین فروش',
    },
  },
  SearchBy: 'جستجو براساس ',
  CreateDate: 'تاریخ ثبت نام',
  List: 'لیست',
  Preview: 'نمایش',
  Edit: 'ویرایش',
  Add: 'افزودن',
  Create: 'ایجاد',
  Delete: 'حذف',
  DeleteAll: 'حذف همه',
  Show: 'مشاهده',
  Update: 'بروزسانی',
  Pages: 'صفحات',
  Error: 'خطا',
  Price: 'قیمت',
  FAQ: 'پرس و پاسخ',
  Select: 'انتخاب',
  NotSelect: 'بدون انتخاب',
  Search: 'جستجو',
  Filter: 'فیلتر',
  Filters: 'فیلتر ها',
  RemoveFilters: 'حذف فیلتر ها',
  Reports: 'گزارش ها',
  Excel: 'اکسل',
  Products: 'محصولات',
  Product: 'محصول',
  IsActive: 'فعال',
  DeActive: 'غیر فعال',
  Save: 'ذخیره',
  Cancel: 'انصراف',
  SubCat: 'زیر دسته',
  NotFound: 'موردی جهت نمایش وجود ندارد',
  ShowAuthCardPic: 'نمایش تصویر احراز',
  UploadAuthCardPic: 'آپلود تصویر احراز',
  AuthCardPic: 'تصویر احراز',
  RejectList: 'لیست رد شده‌ها',
  Reason: 'علت',
  RejectBy: 'رد شده توسط',
  RejectDate: 'رد شده در تاریخ',
  UpdatedDate: 'ویرایش شده در تاریخ',
  CreatedDate: 'ایجاد شده در تاریخ',
  WalletBalance: 'موجودی کیف پول',
  Toman: 'تومان',
  Profile: 'پروفایل',
  Exit: 'خروج',
  DateFrom: 'از تاریخ',
  DateTo: 'تا تاریخ',
  NotSaved: 'ثبت نشده است',
  PhoneVarifiedAt: 'تاریخ تایید شماره موبایل ',
  LastLoginDate: 'آخرین تاریخ ورود به سایت ',
  PageNotFound: 'صفحه ی مورد نظر یافت نشد!',
  PageChangedOrDeleted: 'احتمالا صفحه‌ی مورد نظرشما حذف شده است یا نام آن تغییر کرده است',
  ReturnToMain: 'بازگشت به صفحه‌ی اصلی ',
  Comments: 'نظرات',
  Packages: 'پکیج ها',
  Devices: 'دستگاه ها',
  Wallets: 'کیف پول ها',
  Transactions: 'تراکنش ها',
  Transaction: 'تراکنش ',
  GeneralInfo: 'اطلاعات کلی',
  NameLabel: 'نام',
  Label: 'برچسب',
  UdidNum: 'شماره (udid)',
  Version: 'ورژن',
  Content: 'متن',
  TransactionNum: 'شماره تراکنش',
  Amount: ' مبلغ',
  Status: 'وضعیت',
  Space: ' ',
  TicketTitle: 'عنوان تیکت',
  TicketNum: 'شماره تیکت',
  SenderUser: 'نام کاربر فرستنده',
  SenderPhone: 'شماره کاربر فرستنده',
  ShowTicket: 'مشاهده تیکت',
  Application: 'برنامه',
  UpadatedAt: 'تاریخ به روز رسانی',
  Type: 'نوع',
  Payment: 'پرداختی',
  Income: 'دریافتی',
  DeleteItemConfirm: 'آیا از حذف این آیتم اطمینان دارید؟',
  AnErrorOccured: 'خطایی رخ داده است',
  Send: 'ارسال',
  PerPage: 'سایز صفحه',
  Anariom: 'اناریوم',
  Success: 'موفق',
  UnSuccess: 'ناموفق',
}
