export default {
  BankAccounts: {
    Number: 'شماره ',
    Bank: 'بانک',
    ConfirmDeleteMessage: 'برای حذف این کارت مطمئن هستید؟',
    Status: {
      Pending: 'درحال بررسی',
      Confirmed: 'تایید شده',
      Rejected: 'رد شده',
    },
    Type: {
      Card: 'کارت',
      Sheba: 'شبا',
      Wallet: 'کیف پول',
    },
    ChangeStatus: 'تغییر وضعیت',
    CreatedAt: 'تاریخ ثبت',
    Wage: 'کارمزد بانکی',
  },
}
