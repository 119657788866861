const auth = [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/pages/auth/login/LoginPage.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/pages/auth/resetPassword/ResetPage.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

export default auth
