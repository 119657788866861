import { mdiBulletinBoard } from '@mdi/js'

export default [
  {
    title: 'Logs',
    icon: mdiBulletinBoard,
    action: 'can_see_menu_log',
    resource: 'all',
    children: [
      {
        title: 'لاگ api',
        to: 'log-api',
        action: 'can_see_log_api',
        resource: 'all',
      },
    ],
  },
]
