import { mdiStorePlus } from '@mdi/js'

export default [
  {
    title: 'آگهی ها',
    icon: mdiStorePlus,
    action: 'can_see_markets_menu',
    resource: 'all',
    children: [
      {
        title: 'آگهی های بازارچه',
        to: 'markets',
        action: 'can_see_markets',
        resource: 'all',
      },
      {
        title: 'درخواست های ویرایش',
        to: 'change-request',
        action: 'can_see_change_request',
        resource: 'all',
      },
      {
        title: 'گزارش مشکل',
        to: 'market-report',
        action: 'can_see_add_report',
        resource: 'all',
      },
    ],
  },
]
