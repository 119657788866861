const base = '/panel'
const routes = {
  api: {
    login: '/login',
    reset_password: '/reset_password',

    // city
    get_all_cities: '/places',
    store_place: '/places',
    update_place: '/places/_ID_',
    delete_place: '/places/_ID_',

    // markets
    get_all_markets: '/markets/get_markets',
    market_id: '/markets/markets/_ID_',
    reject_market: '/markets/markets/reject/_ID_',
    get_reports: '/markets/get_reports',
    change_report_status: '/markets/change_report_status',

    get_changes: '/markets/get_change_request',
    change_by_id: '/markets/show_change_request/_ID_',
    approve: '/markets/change_approve/_ID_',

    get_change_market: '/markets/get_change_market/_ID_',
    get_change_business: '/markets/get_change_business/_ID_',
    change_market_approve: '/markets/change_market_approve/_ID_',

    get_logs_api: '/logs/get_api_logs',
    get_categories: '/get_categories',

    product_features_service: '/setting/features',
    product_features_service_id: '/setting/features/_ID_',

    get_all_categories: '/categories/categories',
    get_category: '/categories/categories/_ID_',
    delete_category: '/categories/categories/_ID_',
    store_category: '/categories/categories',
    update_category: '/categories/categories/_ID_',
    reorder_category: '/categories/categories/reorder',
    upload_image: '/categories/categories/upload/image',

    get_all_users: '/users/get_users',
    get_users_statistic_api: '/users/report',
    get_user_info: '/users/show/_ID_',
    change_user_status: '/users/change_status/_ID_',

    get_all_roles: '/access/roles',
    store_role: '/access/roles',
    update_role: '/access/roles/_ID_',
    delete_role: '/access/roles/_ID_',
    role_add_user: '/access/roles/add_user/_ID_',
    role_add_permission: '/access/roles/add_permission/_ID_',

    get_all_pages: '/access/pages',
    store_page: '/access/pages',
    update_page: '/access/pages/_ID_',
    delete_page: '/access/pages/_ID_',

    get_bot_users: '/bots/get_users',
    bot_change_level: '/bots/change_level',
    bot_user_delete: '/bots/delete_user',
    bot_get_text: '/bots/get_text',
    bot_store_text: '/bots/store_text',

    get_all_permissions: '/access/permissions',
    store_permission: '/access/permissions',
    update_permission: '/access/permissions/_ID_',
    delete_permission: '/access/permissions/_ID_',
    get_page_permissions: '/access/permissions/get_page_permissions',

    get_all_business: '/markets/get_business',
    store_ad: '/markets/business',
    get_ad: '/markets/business/_ID_',
    update_ad: '/markets/business/_ID_',
    delete_ad: '/markets/business/_ID_',
    upload_ad_image: '/markets/business/upload/image',

    get_permissions: '/access/get_permissions',

    get_user_payments: '/users/payments/_ID_',
    get_user_authentication_data: '/users/get_auth/_ID_',
    get_user_wallet_data: '/users/get_wallet/_ID_',
    change_user_password: '/users/change_password/_ID_',
    change_user_auth: '/users/approve_auth/_ID_',
    change_wallet_balance: '/users/store_wallet/_ID_',

    users_black_api: '/users/black_lists',
    users_black_api_by_id: '/users/black_lists/_ID_',

    upload_product_image: '/business/business/upload/image',
    get_history_images: '/business/get_history_images',
    store_policy: '/setting/store_policy',
    get_config: '/setting/configs',

    get_api_products: '/api_gift/get_products',
    reload_products: '/api_gift/reload_products',
    get_local_products: '/api_gift/get_local_products',
    product_mapping: '/api_gift/store_product_mapping',
    get_mapping_product_setting: '/api_gift/get_product_setting',
    save_mapping_product_setting: '/api_gift/store_product_setting',

    get_local_categories: '/api_gift/get_local_categories',
    reload_categories: '/api_gift/reload_categories',
    category_mapping: '/api_gift/store_category_mapping',
    change_api_setting: '/api_gift/change_setting',

    get_faq_list: '/setting/faq_list',
    faq_api: '/setting/faq_store/_ID_',
    delete_faq: '/setting/faq_delete/_ID_',

    get_report_sell: '',
    get_inventory_report: '',

    get_payments_all: '/payments/get_payments',
    get_bills: '/bills/get_bills',
    get_dashboard_chart_data: '/get_dashboard_data',
    bill_cancel: '/bills/bill_cancel',
    get_tickets: '/tickets',
    get_by_id_ticket: '/tickets/_ID_',
    edit_ticket: '/tickets/edit_answer/_ID',
    templates: '/tickets/templates',
    edit_templates: '/tickets/templates/_ID',
    comment_ticket: '/tickets/comments',
    get_code_details: '/tickets/get_code_details',
    answer_ticket: '/tickets/store_answer/_ID_',

    get_options: '/products/get_options',
    setting_options: '/setting/options',
    setting_options_id: '/setting/options/_ID_',

    dashboard: '/dashboard',
  },
}
const routeGetters = {
  routeApi: state => url => base + state.api[url],
}
export default {
  state: routes,
  getters: routeGetters,
}
